
import { Button, Card, Form, Input, message } from "antd";

import { useMemo, useEffect, useState } from "react";
import { useMoralis, useNativeBalance } from "react-moralis";
import contractInfo from "../../contracts/contractInfo.json";


import "./App.css";

const styles = {
  title: {
    fontSize: "30px",
    fontWeight: "600",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "5px",
  },
  card: {
    boxShadow: "0 0.5rem 1.2rem rgb(189 197 209 / 20%)",
    border: "1px solid #e7eaf3",
    borderRadius: "1rem",
    width: "650px",
    fontSize: "16px",
    fontWeight: "500",
  },
};


function Photo(props) {

  const [contract, setContract] = useState();
  const { Moralis, isInitialized, chainId } = useMoralis();

  const [photoUrl, setPhotoUrl] = useState("");
  const [tokenId, setTokenId] = useState("");

  const [previewPhotoUrl, setPreviewPhotoUrl] = useState("");

  const [actionType, setActionType] = useState('');

  const [actionFee, setActionFee] = useState(0);
  const [actionFeeDisplay, setActionFeeDisplay] = useState("");

  const [waitActionChange, setWaitActionChange] = useState(false);
  const [waitActionPreview, setWaitActionPreview] = useState(false);
  const [waitActionBurn, setWaitActionBurn] = useState(false);

  const { nativeToken: balance } = useNativeBalance(props);


  useEffect(() => {
    /** Tries to load local contract JSON file or get
     * it from browser localStorage(works only if file was uploaded previosly by drag&drop component) */
     setContract(contractInfo);
     Moralis.onChainChanged(cleanData);

    }, [Moralis]);

  const cleanData = (chain) => {
    console.log("SWITCH ",chain)
    window.location.reload(false);
  };
  

  const callGetPhotoId = async () => {
    if (tokenId === "0" || tokenId === "") {
      const options = {
        contractAddress,
        functionName: "getMyTokenId",
        abi: contract?.abi,
      };
      const tx = await Moralis.executeFunction({ awaitReceipt: false, ...options });
      setTokenId(tx.toString());
    }
    return true;
  };

  const callGetPhotoUrl = async () => {
    if (tokenId === "0" || tokenId === "" || photoUrl !== "") {
      return;
    }
    const options = {
      contractAddress,
      functionName: "tokenURI",
      abi: contract?.abi,
      params: {
        tokenId: tokenId
      }
    };
    const tx = await Moralis.executeFunction({ awaitReceipt: false, ...options });
    const urlValue = tx.toString();

    let realPhotoUrl = urlValue;

    const funcParams =  { targetUrl: urlValue };
    const res = await Moralis.Cloud.run("getUrlInfo", funcParams);
    // eslint-disable-next-line
    if (!res || res.code != 200) {
      message.error("Bad URL, need an image or a JSON");
    } else {
      realPhotoUrl = res.imageUrl;
    }

    setPhotoUrl(realPhotoUrl);
    return realPhotoUrl;
  };

  const callActionFee = async () => {
    if (actionFeeDisplay !== "") {
      return;
    }
    const options = {
      contractAddress,
      functionName: "getFeeValue",
      abi: contract?.abi,
    };
    const tx = await Moralis.executeFunction({ awaitReceipt: false, ...options });
    setActionFee(tx);
    setActionFeeDisplay(Moralis.Units.FromWei(tx));
    return true;
  };


  const callForm = async (name, { forms }) => {
    const params = forms[name].getFieldsValue();
    if (params === undefined) {
      return;
    }
    let urlParamValue = params['newUrlValue'];


    let targetUrl = "";
    // eslint-disable-next-line
    switch(actionType) {
      case 'preview':
        setWaitActionPreview(true);
        break;
      case 'create':
      case 'update':
          setWaitActionChange(true);
        break;
      case 'destroy':
        setWaitActionBurn(true);
        break;
    }


    if (actionType === 'preview' || actionType === 'create' || actionType === 'update') {

      if (params['newUrlValue'] === undefined) {
        setWaitActionChange(false);
        setWaitActionPreview(false);
        return;
      }
      const funcParams =  { targetUrl: urlParamValue };
      const res = await Moralis.Cloud.run("getUrlInfo", funcParams);
      // eslint-disable-next-line
      if (!res || res.code != 200) {
        message.error("Can't read URL, return code : " + res.code);
        setWaitActionChange(false);
        setWaitActionPreview(false);
        setPreviewPhotoUrl("");
        return;
      }
      if (res.type !== 'json' || res.imageUrl === '') {
        message.error("Bad URL, need a JSON");
        setWaitActionChange(false);
        setWaitActionPreview(false);
        setPreviewPhotoUrl("");
        return;
      }
      targetUrl = res.imageUrl;
    }

    if (actionType === 'preview') {
      setPreviewPhotoUrl(targetUrl);
      setWaitActionPreview(false);
    } else if (actionType === 'update') {
      const options = {
        contractAddress,
        functionName: "setTokenURI",
        abi: contract?.abi,
        params: {
          tokenId: tokenId,
          _tokenURI: urlParamValue
        }
      };
      if (actionFee > 0) {
        options.msgValue = actionFee
      }
      const transaction = await Moralis.executeFunction(options);
      console.log(transaction.hash)
      // Wait until the transaction is confirmed
      await transaction.wait();

      setPhotoUrl(targetUrl);
      setPreviewPhotoUrl("");
      setWaitActionChange(false);

    } else if (actionType === 'create') {
      const options = {
        contractAddress,
        functionName: "createPhoto",
        abi: contract?.abi,
        params: {
          _photoUrl: urlParamValue
        }
      };
      if (actionFee > 0) {
        options.msgValue = actionFee
      }
      const transaction = await Moralis.executeFunction(options);
      console.log(transaction.hash)
      // Wait until the transaction is confirmed
      await transaction.wait();

      setPhotoUrl(targetUrl);
      setPreviewPhotoUrl("");
      setWaitActionChange(false);

    } else if (actionType === 'destroy') {
      const options = {
        contractAddress,
        functionName: "burn",
        abi: contract?.abi,
        params: {
          tokenId: tokenId,
        }
      };
      const transaction = await Moralis.executeFunction(options);
      console.log(transaction.hash)
      // Wait until the transaction is confirmed
      await transaction.wait();

      setTokenId(0);
      setPhotoUrl("");
      setPreviewPhotoUrl("");
      setWaitActionBurn(false);
    }
  };


    /** Returns true in case if contract is deployed to active chain in wallet */
    const isDeployedToActiveChain = useMemo(() => {
      if (!contract?.networks){
        return undefined;
      } 
      return [parseInt(chainId, 16)] in contract.networks;
    }, [contract, chainId]);
  
    const contractAddress = useMemo(() => {
      if (!isDeployedToActiveChain) return null;
      return contract.networks[parseInt(chainId, 16)]?.["address"] || null;
    }, [chainId, contract, isDeployedToActiveChain]);
  

  return (
    <Card
      style={styles.card}
    >
      {isDeployedToActiveChain === true && callActionFee() && ( <></>)}
      {isDeployedToActiveChain === true && callGetPhotoId() && callGetPhotoUrl() && ( <></>)}

      {isDeployedToActiveChain === true && isInitialized === true && (
        <>
        <div className="col-sm-6 col-md-3 col-lg-3 profilephoto">
          {photoUrl !== "" && (
            <svg height="200" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <pattern id="img" patternUnits="userSpaceOnUse" width="100" height="100">
                  <image xlinkHref={photoUrl} x="-25" width="150" height="100" />
                </pattern>
              </defs>
              <polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill="url(#img)"/>
              </svg>
          )}
          {previewPhotoUrl !== "" && (
            <svg height="200" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <pattern id="imgPreview" patternUnits="userSpaceOnUse" width="100" height="100">
                  <image xlinkHref={previewPhotoUrl} x="-25" width="150" height="100" />
                </pattern>
              </defs>
              <polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill="url(#imgPreview)"/>
              </svg>
          )}
        </div>
        <Form.Provider onFormFinish={callForm} >
          <Card title={(photoUrl !== "" ? "Update photo" : "Create photo") + (actionFee > 0 ? " : fee="+actionFeeDisplay+" " + balance.symbol : "" )} size="small" style={{ marginBottom: "20px" }} key="g">
            <Form layout="vertical" name="rr">

                Enter the URL of your NFT description (respect this <a target="_blank" rel="noreferrer" href="https://docs.opensea.io/docs/metadata-standards">format</a>)
                <br/>Like : https://nftphoto.liard.me/api/avatar/&lt;github nickname&gt;
                <Form.Item
                  name="newUrlValue"
                  style={{ marginBottom: "15px" }}
                  key="ff"
                >
                  <Input placeholder="Json file URL" name="myURL" autoComplete="false" data-form-type="other"/>
                </Form.Item>
              <Form.Item style={{ marginBottom: "15px" }}>
                <Button type="primary" htmlType="submit" disabled={waitActionPreview || waitActionBurn} loading={waitActionChange} onClick={e => {
                      setActionType(photoUrl !== "" ? 'update' : 'create');
                    }}>
                  {photoUrl !== "" ? 'Update' : 'Create'}
                </Button>
                <Button type="primary" htmlType="submit" disabled={waitActionChange || waitActionBurn} loading={waitActionPreview} 
                  style={{ marginLeft: "20px" }} onClick={e => {
                      setActionType('preview');
                    }}>
                  Preview
                </Button>
              </Form.Item>
              {photoUrl !== "" && (
                <Form.Item style={{ marginBottom: "5px" }}>
                  <Button type="danger" htmlType="submit" disabled={waitActionChange || waitActionPreview} loading={waitActionBurn} onClick={e => {
                        setActionType('destroy');
                      }}>
                    Burn NFT
                  </Button>
                </Form.Item>
              )}
            </Form>
          </Card>
        </Form.Provider>
        </>
        
      )}


      {isDeployedToActiveChain === false && (
        <>{`The contract is not deployed to the active ${chainId} chain. Switch your active chain or try agan later.`}</>
      )}

    </Card>
  );
}

export default Photo;
